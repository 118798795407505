import React, { useState, useEffect } from "react"
import Layout from "../../Layout/layout"
import AxiosInstance from "../../../services/axiosInstance"
import { Link } from "gatsby"
import Settings from "./settings"
import "./scheduler.css"
import Services from "./services"
import Installation from "./installation/installation"
import MoreOptions from "./moreOptions"
import { getLoginUserId } from "../../../services/auth"
import { getUserName } from "../Doc/Service/docHelper"

const Scheduler = ({ fileId }) => {
  const [states, setStates] = useState({
    availabilityJson: {
      minimumNotice: "1 hour",
      timeOpt: "Simple",
      availableDayMon: "mon",
      availableDayTue: "tue",
      availableDayWed: "wed",
      availableDayThu: "thu",
      availableDayFri: "fri",
      earlierHrsMon: "09:00",
      laterHrsMon: "17:00",
      earlierHrsTue: "00:00",
      laterHrsTue: "00:00",
      blacklistSwiftCalList: "This calendar",
    },
    fileId,
    isCustomizedUrl: false,
    isDataLoaded: false,
  })
  const username = getUserName()

  const loadSchedulerData = async () => {
    try {
      const schedulerResponse = await AxiosInstance.get(`/drive/scheduler/${fileId}`)
      if (schedulerResponse.status == 200) {
        const availabilityJson =
          Object.keys(schedulerResponse.data.scheduler.availabilityJson).length > 0
            ? schedulerResponse.data.scheduler.availabilityJson
            : states.availabilityJson

        const drive = schedulerResponse.data.scheduler.drive
        setStates({
          ...states,
          ...schedulerResponse.data.scheduler,
          availabilityJson,
          previewUrl:
            drive.mappedUrl?.mappedUrl ??
            `${process.env.GATSBY_PREVIEW_URL}/${username}/${drive.slug ?? "scheduler/" + fileId}`,
          isCustomizedUrl: drive.mappedUrl?.mappedUrl !== undefined,
          isDataLoaded: true,
        })
        return
      }
      console.log("Unexpected response status code: ", schedulerResponse.status)
    } catch (error) {
      console.log("Error when creating scheduler: ", error.message)
    }
  }

  useEffect(() => {
    loadSchedulerData()
  }, [])

  const updateData = (e, fieldName) => {
    setStates({ ...states, [fieldName]: e.target.value })
  }

  const saveScheduler = async e => {
    const { availabilityJson, description, name, fileToUpload, moreOptions } = states
    try {
      const schedulerResponse = await AxiosInstance.post(
        `/drive/scheduler/${fileId}`,
        {
          availabilityJson,
          user_id: getLoginUserId(),
          name: name,
          file_icon: fileToUpload,
          historyJson: JSON.stringify([]),
          description,
          currentFileIcon: states.drive.fileIcon,
          moreOptions,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      if (schedulerResponse.status === 202) {
        console.log("Update success")
      } else {
        console.error("Unexpected status code:", schedulerResponse.status)
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data)
      } else if (error.request) {
        console.error("No response received:", error.request)
      } else {
        console.error("Request setup error:", error.message)
      }
    }
  }

  return (
    <Layout>
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col" id="page-urls">
            <div className="row g-0">
              <div className="col-lg-12 mb-3">
                <div className="pageTitle_Editor">
                  <Link to="/drive" className="goBack">
                    <i className="bi bi-arrow-90deg-left"></i>
                  </Link>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Untitled Scheduler"
                    value={states.name ?? ""}
                    onChange={e => updateData(e, "name")}
                  />
                </div>
              </div>
            </div>
            <div className="row g-0">
              <div className="col-lg-12 mb-3">
                <div className="row g-0">
                  <Settings states={states} setStates={setStates} />
                  <Services states={states} setStates={setStates} />
                  <Installation states={states} setStates={setStates} />
                  <MoreOptions states={states} setStates={setStates} />

                  <div className="col-lg-12 footer-action mb-3 text-center">
                    <div className="footer-action-btns">
                      <button
                        className={`btnTogglePanel${states.showInstallationPanel ? " active" : ""}`}
                        data-panel="installation_panel"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Using this Scheduler"
                        aria-label="Using this Scheduler"
                        onClick={e =>
                          setStates({ ...states, showInstallationPanel: !states.showInstallationPanel })
                        }
                      >
                        <i className="bi bi-megaphone-fill"></i>
                      </button>{" "}
                      <button
                        className={`btnTogglePanel${states.showMoreOptionsPanel ? " active" : ""}`}
                        data-panel="more_panel"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="More Options"
                        aria-label="More Options"
                        onClick={e =>
                          setStates({ ...states, showMoreOptionsPanel: !states.showMoreOptionsPanel })
                        }
                      >
                        <i className="bi bi-three-dots"></i>
                      </button>{" "}
                    </div>
                  </div>
                  <div className="col-lg-12 text-center footer-action">
                    <div className="btn-group btnDropdownWithSeparater">
                      <button type="button" className="btn btn-primary btn-lg" onClick={saveScheduler}>
                        <i className="bi bi-check2"></i> Save
                      </button>{" "}
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="visually-hidden">Toggle Dropdown</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-layers-half"></i> Save as Copy
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-subtract"></i> Clone a Template
                          </a>
                        </li>
                      </ul>
                    </div>{" "}
                    <a
                      href={states.previewUrl}
                      type="button"
                      className="btn btn-secondary btn-lg"
                      target="_blank"
                    >
                      <i className="bi bi-box-arrow-up-right" /> Preview
                    </a>{" "}
                    <button type="button" className="btn btn-magic btn-lg">
                      <i className="bi bi-send-fill"></i> Send
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-default btn-lg btnOpenChatDock"
                      data-bs-toggle="tooltip"
                      title=""
                      data-bs-original-title="History"
                      aria-label="History"
                    >
                      <i className="bi bi-arrow-counterclockwise"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Scheduler
