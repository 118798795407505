import React from "react"

const AdvancedTime = ({ states, setStates, updateData }) => {
  return (
    <>
      <div className="mb-3 advTime">
        <div role="alert" className="alert alert-warning">
          <i className="bi bi-exclamation-triangle-fill"></i> This mode is still in preview only
        </div>
        <p>
          Link to a calendar of available times.
          <br />
          All scheduled time-blocks will be shown as available.
          <br />
          We'll then subtract appointments from it.
        </p>
      </div>
      <div className="mb-3 form-inline advTime">
        <div className="row row-cols-sm-auto g-2 align-items-center">
          <div className="col-12">
            <input
              type="radio"
              name="advCalOpt"
              id="advCalOptSwiftCal"
              value="SwiftCalendar"
              className="css-checkbox"
              checked={states.availabilityJson?.advCalOpt === "SwiftCalendar" ?? false}
              onChange={updateData}
            />
            <label htmlFor="advCalOptSwiftCal" className="css-radio-label mr-2">
              SwiftCloud Calendar
            </label>
          </div>
          <div className="col-12">
            <select
              name="swiftCalList"
              id="swiftCalList"
              className="form-select"
              value={states.availabilityJson?.swiftCalList ?? ""}
              onChange={updateData}
            >
              <option>My Calendar (Main)</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mb-3 form-inline advTime">
        <div className="row row-cols-sm-auto g-2 align-items-center">
          <div className="col-12">
            <input
              type="radio"
              name="advCalOpt"
              id="advCalOptiCal"
              value="iCal"
              className="css-checkbox"
              checked={states.availabilityJson?.advCalOpt === "iCal" ?? false}
              onChange={updateData}
            />
            <label htmlFor="advCalOptiCal" className="css-radio-label ">
              iCal Feed URL
            </label>
          </div>
          <div className="col-12">
            <input
              type="url"
              name="iCalFeedUrl"
              id="iCalFeedUrl"
              placeholder="https://"
              value={states.availabilityJson?.iCalFeedUrl ?? ""}
              onChange={updateData}
              className="form-control ml-2"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdvancedTime
