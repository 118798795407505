import * as React from "react"
import Scheduler from "../../../components/Drive/Scheduler/scheduler"
import Seo from "../../../components/seo"
import PrivateRoute from "../../../components/privateRoute"
import ReduxWrapper from "../../../redux/reduxWrapper"

const SchedulerPage = props => {
  return (
    <React.Fragment>
      <Seo title="Scheduler" />
      <PrivateRoute path="/" component={Scheduler} fileId={props.fileId} location={props.location} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<SchedulerPage {...props} />} />
export default WrappedPage
