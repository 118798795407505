import React, { useState, useEffect } from "react"
import SchedulerServiceModal from "../Popups/schedulerServiceModal"
import AxiosInstance from "../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Pagination from "../../Global/pagination"

const Services = ({ states, setStates }) => {
  const [showServiceModal, setShowServiceModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")

  const loadLinkedServices = async page => {
    setCurrentPage(page)
    let queryString = ""
    if (page !== 1) {
      queryString += `?page=${page}`
    }

    if (searchTerm.length > 0) {
      queryString += `${queryString.length > 0 ? "&" : "?"}search=${searchTerm}`
    }

    try {
      const response = await AxiosInstance.get(`/drive/service/scheduler/${states.fileId}${queryString}`)
      if (response.status == 200) {
        setStates({ ...states, services: response.data.services })
        setPageCount(response.data.pages_count)
        setTotalItems(response.data.total_services)

        setIsLoading(false)

        return
      }
      console.log("Unexpected response status code: ", response.status)
    } catch (error) {
      console.log("Error when creating scheduler: ", error.message)
    }
  }

  useEffect(() => {
    if (states.isDataLoaded) loadLinkedServices(1)
  }, [states.isDataLoaded])

  const deleteService = async (e, serviceId) => {
    e.preventDefault()

    if (window.confirm(`Are you sure, you want to delete this scheduler service?`)) {
      const response = await AxiosInstance.delete(`/drive/${serviceId}`)
      if (response.status === 201 && response.data.success) {
        loadLinkedServices(1)
      }
    }
  }

  const onSearchBlur = e => {
    loadLinkedServices(1)
  }

  return (
    <>
      <div className="col-lg-12 mb-3" id="services_panel">
        <div className="row tabularMenuContainer align-items-end">
          <div className="col">
            <div className="input-group filterDropdown">
              <input
                type="search"
                className="form-control tableSearch mb-1"
                placeholder="Search"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onBlur={onSearchBlur}
              />
            </div>
          </div>
          <div className="col-sm-8">
            <div className="float-end">
              <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex mb-1" role="tablist">
                <li className="nav-item">
                  <button
                    className="btn btn-default btnSchedulerSettings"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="Settings"
                    aria-label="Settings"
                  >
                    <i className="bi bi-gear-fill"></i>
                  </button>
                </li>
                <li className="nav-item">
                  <button className="btn btn-secondary ms-1">
                    <i className="bi bi-calendar3"></i> My Calendar
                  </button>
                </li>
                <li className="nav-item">
                  <button className="btn btn-primary ms-1" onClick={e => setShowServiceModal(true)}>
                    <i className="bi bi-plus-lg"></i> Service / Appointment Type
                  </button>
                </li>
              </ul>

              <ul className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1" role="tablist">
                <li className="nav-item">
                  <div className="btn-group btnAddNewDropdown" role="group">
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi bi-plus-lg"></i>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="contact_add.html">
                          <i className="bi bi-person-fill"></i> Contact
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#newAudienceGroupModal" data-bs-toggle="modal">
                          <i className="bi bi-people-fill"></i> Audience / Group / Tribe
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="bi bi-table"></i> Upload CSV
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="btn-group btnDriveView" role="group">
                    <button
                      type="button"
                      className="btn btn-default dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi bi-person-fill"></i>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          data-bs-toggle="tab"
                          data-bs-target="#contacts-tab-pane"
                          role="tab"
                          aria-controls="contacts-tab-pane"
                          aria-selected="false"
                        >
                          <i className="bi bi-person-fill"></i> Contacts
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          data-bs-toggle="tab"
                          data-bs-target="#contacts-trash-pane"
                          role="tab"
                          aria-controls="contacts-trash-pane"
                          aria-selected="false"
                        >
                          <i className="bi bi-trash-fill"></i> Trash
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row tabularMenuContainer">
          <div className="col">
            <table id="tblContacts" className="table dataTable" cellSpacing="0" width="100%">
              <thead>
                <tr>
                  <th width="5%">&nbsp;</th>
                  <th width="30%">Service</th>
                  <th width="13%" className="d-none d-lg-table-cell">
                    Time Block
                  </th>
                  <th width="13%" className="d-none d-lg-table-cell">
                    Location
                  </th>
                  <th width="13%" className="d-none d-lg-table-cell">
                    Cost
                  </th>
                  <th width="13%" className="d-none d-lg-table-cell">
                    Paperwork
                  </th>
                  <th width="13%" className="d-none d-md-table-cell">
                    {" "}
                    Last Activity / Status
                  </th>
                </tr>
                <tr className="d-none table_action_container">
                  <td colSpan="7" className="text-left">
                    <div className="batch-action-container">
                      <button className="btn btn-trash">
                        <i className="bi bi-trash-fill"></i> Move to Trash
                      </button>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {!isLoading && (states.services === undefined || states.services.length === 0) && (
                  <tr className="odd">
                    <td valign="top" colSpan="7" className="dataTables_empty">
                      <h3 className="mb-3 d-inline-block">No Services / Time-Blocks listed yet. </h3>
                      <br />
                      Why not{" "}
                      <a href="#" onClick={e => setShowServiceModal(true)} className="addNewServiceTable">
                        click here to create some now?
                      </a>
                    </td>
                  </tr>
                )}

                {states.services?.map((service, index) => {
                  return (
                    <tr key={service.id}>
                      <td className="text-center tableColChkDel">
                        <div className="tableColChkDelBox">
                          <input
                            type="checkbox"
                            name="fileId[]"
                            id={`serviceId${service.id}`}
                            className="css-checkbox"
                          />
                          <label htmlFor={`serviceId${service.id}`} className="css-checkbox-label"></label>
                          <span className="tableColChkSpan btn-secondary">
                            <i className="bi bi-calendar2-plus"></i>
                          </span>
                        </div>
                      </td>
                      <td className="nowrap">
                        <a href={`/drive/scheduler/${states.fileId}/service/${service.id}`}>{service.name}</a>
                      </td>
                      <td className="d-none d-lg-table-cell">{`${service.timeRequiredJson?.timeReq ?? ""} ${
                        service.timeRequiredJson?.timeFreq ?? ""
                      }`}</td>
                      <td className="d-none d-lg-table-cell">{`${
                        service.locationJson?.locationOpt ?? ""
                      }`}</td>
                      <td className="d-none d-lg-table-cell">{`${service.price ?? "free"}`}</td>
                      <td className="d-none d-lg-table-cell">None</td>
                      <td className="d-none d-md-table-cell">
                        <div className="lastActivityBox">
                          <time className="timeago" dateTime="2022-05-01"></time>
                        </div>
                        <div className="statusActionBox">
                          <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
                            <a
                              href={`/drive/scheduler/${states.fileId}/service/${service.id}`}
                              className="btn btn-edit"
                              data-bs-toggle="tooltip"
                              title=""
                              data-bs-original-title="Edit"
                              aria-label="Edit"
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </a>
                          </OverlayTrigger>
                          <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                            <a
                              href="#"
                              className="btn btn-delete"
                              data-bs-toggle="tooltip"
                              title=""
                              data-bs-original-title="Delete"
                              aria-label="Delete"
                              onClick={e => deleteService(e, service.drive.id)}
                            >
                              <i className="bi bi-trash-fill"></i>
                            </a>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              pageCount={pageCount}
              onChange={loadLinkedServices}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
      <SchedulerServiceModal
        showModal={showServiceModal}
        setShowModal={setShowServiceModal}
        states={states}
        setStates={setStates}
      />
    </>
  )
}

export default Services
