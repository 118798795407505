import React, { useEffect, useState } from "react"
import HourSelect from "./hourSelect"
import Holidays from "./holidays"
import { ucFirst } from "../../../Global/helpers"

const SimpleTime = ({ states, setStates, updateData }) => {
  const updateDataByDay = e => {
    let availabilityJson = { ...states.availabilityJson }

    if (!e.target.checked) {
      const day = ucFirst(availabilityJson[e.target.name])
      delete availabilityJson[e.target.name]
      delete availabilityJson[`earlierHrs${day}`]
      delete availabilityJson[`laterHrs${day}`]
      setStates({ ...states, availabilityJson })
      return
    }

    const day = ucFirst(e.target.value)
    const earlierHrs = ["sat", "sun"].includes(e.target.value) ? "00:00" : "09:00"
    const laterHrs = ["sat", "sun"].includes(e.target.value) ? "00:00" : "17:00"
    availabilityJson = {
      ...availabilityJson,
      [e.target.name]: e.target.value,
      [`earlierHrs${day}`]: earlierHrs,
      [`laterHrs${day}`]: laterHrs,
    }
    setStates({ ...states, availabilityJson })
  }

  return (
    <>
      <div className="mb-3 row availableWindowContainer simpleTime">
        <div className="col-lg-12">
          <div className="table-responsive">
            <table className="table dataTable tblAvailableTimes">
              <thead>
                <tr className="blue-th">
                  <th>
                    <div className="toggleSwitch">
                      <input
                        type="checkbox"
                        value="mon"
                        name="availableDayMon"
                        id="avail-time-mon-switch"
                        checked={states.availabilityJson?.availableDayMon === "mon"}
                        onChange={updateDataByDay}
                      />
                      <label>Mon</label>
                    </div>
                  </th>
                  <th>
                    <div className="toggleSwitch">
                      <input
                        type="checkbox"
                        value="tue"
                        name="availableDayTue"
                        id="avail-time-tue-switch"
                        checked={states.availabilityJson?.availableDayTue === "tue"}
                        onChange={updateDataByDay}
                      />
                      <label>Tue</label>
                    </div>
                  </th>
                  <th>
                    <div className="toggleSwitch">
                      <input
                        type="checkbox"
                        value="wed"
                        name="availableDayWed"
                        id="avail-time-web-switch"
                        checked={states.availabilityJson?.availableDayWed === "wed"}
                        onChange={updateDataByDay}
                      />
                      <label>Wed</label>
                    </div>
                  </th>
                  <th>
                    <div className="toggleSwitch">
                      <input
                        type="checkbox"
                        value="thu"
                        name="availableDayThu"
                        id="avail-time-thu-switch"
                        checked={states.availabilityJson?.availableDayThu === "thu"}
                        onChange={updateDataByDay}
                      />
                      <label>Thu</label>
                    </div>
                  </th>
                  <th>
                    <div className="toggleSwitch">
                      <input
                        type="checkbox"
                        value="fri"
                        name="availableDayFri"
                        id="avail-time-fri-switch"
                        checked={states.availabilityJson?.availableDayFri === "fri"}
                        onChange={updateDataByDay}
                      />
                      <label>Fri</label>
                    </div>
                  </th>
                  <th>
                    <div className="toggleSwitch">
                      <input
                        type="checkbox"
                        value="sat"
                        name="availableDaySat"
                        id="avail-time-sat-switch"
                        checked={states.availabilityJson?.availableDaySat === "sat"}
                        onChange={updateDataByDay}
                      />
                      <label>Sat</label>
                    </div>
                  </th>
                  <th>
                    <div className="toggleSwitch">
                      <input
                        type="checkbox"
                        value="sun"
                        name="availableDaySun"
                        id="avail-time-sun-switch"
                        checked={states.availabilityJson?.availableDaySun === "sun"}
                        onChange={updateDataByDay}
                      />
                      <label>Sun</label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="daystr">
                  <td className="monTD">
                    {states.availabilityJson?.availableDayMon && (
                      <HourSelect
                        id="earlierHrsMon"
                        name={"earlierHrsMon"}
                        onChange={updateData}
                        value={states.availabilityJson?.earlierHrsMon ?? "09:00"}
                      />
                    )}
                  </td>
                  <td className="tueTD">
                    {states.availabilityJson?.availableDayTue && (
                      <HourSelect
                        id="earlierHrsTue"
                        name="earlierHrsTue"
                        onChange={updateData}
                        value={states.availabilityJson?.earlierHrsTue ?? "09:00"}
                      />
                    )}
                  </td>
                  <td className="wedTD">
                    {states.availabilityJson?.availableDayWed && (
                      <HourSelect
                        id="earlierHrsWed"
                        name="earlierHrsWed"
                        onChange={updateData}
                        value={states.availabilityJson?.earlierHrsWed ?? "09:00"}
                      />
                    )}
                  </td>
                  <td className="thuTD">
                    {states.availabilityJson?.availableDayThu && (
                      <HourSelect
                        id="earlierHrsThu"
                        name="earlierHrsThu"
                        onChange={updateData}
                        value={states.availabilityJson?.earlierHrsThu ?? "09:00"}
                      />
                    )}
                  </td>
                  <td className="friTD">
                    {states.availabilityJson?.availableDayFri && (
                      <HourSelect
                        id="earlierHrsFri"
                        name="earlierHrsFri"
                        onChange={updateData}
                        value={states.availabilityJson?.earlierHrsFri ?? "09:00"}
                      />
                    )}
                  </td>
                  <td className="satTD">
                    {states.availabilityJson?.availableDaySat && (
                      <HourSelect
                        id="earlierHrsSat"
                        name="earlierHrsSat"
                        onChange={updateData}
                        value={states.availabilityJson?.earlierHrsSat ?? "00:00"}
                      />
                    )}
                  </td>
                  <td className="sunTD">
                    {states.availabilityJson?.availableDaySun && (
                      <HourSelect
                        id="earlierHrsSun"
                        name="earlierHrsSun"
                        onChange={updateData}
                        value={states.availabilityJson?.earlierHrsSun ?? "00:00"}
                      />
                    )}
                  </td>
                </tr>
                <tr className="daystr">
                  <td className="monTD">
                    {states.availabilityJson?.availableDayMon && (
                      <HourSelect
                        id="laterHrsMon"
                        name={"laterHrsMon"}
                        onChange={updateData}
                        value={states.availabilityJson?.laterHrsMon ?? "17:00"}
                      />
                    )}
                  </td>
                  <td className="tueTD">
                    {states.availabilityJson?.availableDayTue && (
                      <HourSelect
                        id="laterHrsTue"
                        name="laterHrsTue"
                        onChange={updateData}
                        value={states.availabilityJson?.laterHrsTue ?? "09:00"}
                      />
                    )}
                  </td>
                  <td className="wedTD">
                    {states.availabilityJson?.availableDayWed && (
                      <HourSelect
                        id="laterHrsWed"
                        name="laterHrsWed"
                        onChange={updateData}
                        value={states.availabilityJson?.laterHrsWed ?? "09:00"}
                      />
                    )}
                  </td>
                  <td className="thuTD">
                    {states.availabilityJson?.availableDayThu && (
                      <HourSelect
                        id="laterHrsThu"
                        name="laterHrsThu"
                        onChange={updateData}
                        value={states.availabilityJson?.laterHrsThu ?? "09:00"}
                      />
                    )}
                  </td>
                  <td className="friTD">
                    {states.availabilityJson?.availableDayFri && (
                      <HourSelect
                        id="laterHrsFri"
                        name="laterHrsFri"
                        onChange={updateData}
                        value={states.availabilityJson?.laterHrsFri ?? "09:00"}
                      />
                    )}
                  </td>
                  <td className="satTD">
                    {states.availabilityJson?.availableDaySat && (
                      <HourSelect
                        id="laterHrsSat"
                        name="laterHrsSat"
                        onChange={updateData}
                        value={states.availabilityJson?.laterHrsSat ?? "00:00"}
                      />
                    )}
                  </td>
                  <td className="sunTD">
                    {states.availabilityJson?.availableDaySun && (
                      <HourSelect
                        id="laterHrsSun"
                        name="laterHrsSun"
                        onChange={updateData}
                        value={states.availabilityJson?.laterHrsSun ?? "00:00"}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Holidays states={states} setStates={setStates} />
    </>
  )
}

export default SimpleTime
