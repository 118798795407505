import React, { useEffect, useState, useRef } from "react"
import SimpleTime from "./availability/simpleTime"
import AdvancedTime from "./availability/advancedTime"

const Settings = ({ states, setStates }) => {
  const [imageSrc, setImageSrc] = useState(`${process.env.GATSBY_WRANGLER_URL}/StaticImages/avatar.gif`)
  const inputFileRef = useRef(null)

  const updateData = e => {
    setStates({
      ...states,
      availabilityJson: { ...states.availabilityJson, [e.target.name]: e.target.value },
    })
  }

  const updateMainField = e => {
    setStates({
      ...states,
      [e.target.name]: e.target.value,
    })
  }

  const openFileSelect = () => {
    inputFileRef.current.click()
  }

  const imageChanged = e => {
    setStates({ ...states, fileToUpload: e.target.files[0] })

    if (e.target.files[0]) {
      const reader = new FileReader()
      reader.onload = () => {
        setImageSrc(reader.result)
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  useEffect(() => {
    if (states.drive?.fileIcon) {
      setImageSrc(`${process.env.GATSBY_WRANGLER_URL}${states.drive.fileIcon}`)
    }
  }, [states.drive?.fileIcon])

  return (
    <>
      <div className="col-lg-12 mb-3" id="paywall_rules_panel">
        <div className="accordion" id="paywallPanelAccordion">
          <div className="accordion-item panel panel-white">
            <div className="accordion-header">
              <div
                className="accordion-button"
                data-bs-toggle="collapse"
                data-bs-target="#paywall_rules_container"
                aria-expanded="true"
                aria-controls="paywall_rules_container"
              >
                <span className="panel-heading-icon">
                  <i className="bi bi-gear-fill"></i>
                </span>
                <span className="panel-heading-title">Availability Settings</span>
              </div>
            </div>
            <div
              id="paywall_rules_container"
              className="accordion-collapse collapse show"
              aria-labelledby="paywall_rules_container"
            >
              <div className="accordion-body">
                <div className="row g-0">
                  <div className="col-lg-4 scheduleForContainer">
                    <div className="row g-0">
                      <div className="col-lg-12 colHeading">
                        <strong>
                          <i className="bi bi-calendar3"></i> This is a scheduler for...
                        </strong>
                      </div>
                      <div className="col-lg-12 dh-block-body p-3">
                        <div className="row">
                          <div className="col-lg-12 text-center mb-3">
                            <div className="adbook-profile-pic">
                              <img width="160" height="160" src={`${imageSrc}`} className="rounded-circle" />{" "}
                              <button
                                type="button"
                                data-bs-toggle="tooltip"
                                title=""
                                className="btn-default btnEditProfilePic"
                                data-bs-original-title="Update your avatar"
                                aria-label="Update your avatar"
                                onClick={openFileSelect}
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                              <input
                                type="file"
                                accept="image/*"
                                className="d-none"
                                ref={inputFileRef}
                                onChange={imageChanged}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mb-2">
                            <textarea
                              id="detailsOpts"
                              cols="30"
                              rows="5"
                              name="description"
                              className="form-control"
                              value={states.description}
                              onChange={updateMainField}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 availableSettingsContainer">
                    <div className="row g-0">
                      <div className="col-lg-12 colHeading">
                        <strong>
                          <i className="bi bi-calendar-plus"></i> Available Times
                        </strong>
                      </div>
                      <div className="col-lg-12 dh-block-body p-3">
                        <div className="row g-0">
                          <div className="col-lg-12">
                            <div className="row g-0">
                              <div className="col-lg-12 mb-2">
                                <div className="row row-cols-sm-auto g-2 align-items-center">
                                  <div className="col-12">Minimum Notice</div>
                                  <div className="col-12">
                                    <select
                                      name="minimumNotice"
                                      className="form-select"
                                      onChange={updateData}
                                      value={states.availabilityJson?.minimumNotice ?? "1 hour"}
                                    >
                                      <option value="None">None</option>{" "}
                                      <option value="30 Minutes">30 Minutes</option>{" "}
                                      <option value="1 hour">1 hour</option>{" "}
                                      <option value="2 hours">2 hours</option>{" "}
                                      <option value="4 hours">4 hours</option>{" "}
                                      <option value="8 hours">8 hours</option>{" "}
                                      <option value="12 hours">12 hours</option>{" "}
                                      <option value="24 hours">24 hours</option>{" "}
                                      <option value="48 hours">48 hours</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <input
                                    type="radio"
                                    name="timeOpt"
                                    id="timeOptSimple"
                                    value="Simple"
                                    checked={["Simple", undefined].includes(states.availabilityJson?.timeOpt)}
                                    className="css-checkbox"
                                    onChange={updateData}
                                  />
                                  <label htmlFor="timeOptSimple" className="css-radio-label mr-2">
                                    Simple: Available Times
                                  </label>
                                  &nbsp;&nbsp;
                                  <input
                                    type="radio"
                                    name="timeOpt"
                                    id="timeOptAdv"
                                    value="Advanced"
                                    className="css-checkbox"
                                    checked={states.availabilityJson?.timeOpt === "Advanced" ?? false}
                                    onChange={updateData}
                                  />
                                  <label htmlFor="timeOptAdv" className="css-radio-label ">
                                    Advanced: Calendar Whitelist
                                  </label>
                                </div>

                                {states.availabilityJson?.timeOpt === "Simple" && (
                                  <SimpleTime states={states} setStates={setStates} updateData={updateData} />
                                )}

                                {states.availabilityJson?.timeOpt === "Advanced" && (
                                  <AdvancedTime
                                    states={states}
                                    setStates={setStates}
                                    updateData={updateData}
                                  />
                                )}
                              </div>
                              <div className="col-lg-12 mt-3 blacklistCalContainer">
                                <div className="row g-0">
                                  <div className="col-lg-12 colHeading">
                                    <i className="bi bi-calendar-minus"></i> Blacklist Calendar
                                  </div>
                                  <div className="col-lg-12 dh-block-body">
                                    <div className="row row-cols-sm-auto g-2 align-items-center">
                                      <div className="col-12">Subtract / disallow times from</div>
                                      <div className="col-12">
                                        <select
                                          name="blacklistSwiftCalList"
                                          id="blacklistSwiftCalList"
                                          className="form-select"
                                          value={states.availabilityJson?.blacklistSwiftCalList}
                                          onChange={updateData}
                                        >
                                          <option value="This calendar">
                                            This calendar (Test Scheduler)
                                          </option>{" "}
                                          <option value="All Calendars">All Calendars</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings
