import React, { useRef, useState } from "react"

const Holidays = ({ states, setStates }) => {
  const inputRef = useRef(null)
  const [inputValue, setInputValue] = useState("")

  const removeHoliday = i => {
    let holidayList = states.holidays?.split(",") ?? []
    setHolidays(holidayList.filter((holiday, index) => i !== index).join(","))
  }

  const changeHolidays = e => {
    let newValue = e.target.value

    if (newValue.length > 5) {
      return
    }

    if (inputValue.length > newValue.length) {
      setInputValue(newValue)
      return
    }

    let [month, day] = newValue.split("/")

    if (isNaN(month)) {
      return
    }

    if (newValue.length === 1) {
      if (!isNaN(month) && parseInt(month) > 1) {
        newValue = `0${newValue}/`
      }
      setInputValue(newValue)

      return
    }

    if (newValue.length === 2) {
      if (parseInt(month) <= 12 && !newValue.includes("/")) {
        newValue += "/"
      } else {
        newValue = `0${month}/`
      }

      setInputValue(newValue)

      return
    }

    if (isNaN(day)) {
      return
    }

    if (newValue.length === 4) {
      if (!isNaN(newValue[3]) && parseInt(newValue[3]) >= 4) {
        newValue = `${month}/0${newValue[3]}`
      }
      setInputValue(newValue)
      return
    }

    if (newValue.length === 5) {
      if (parseInt(day) <= 31) {
        setInputValue(newValue)
      }

      return
    }
  }

  const setHolidays = newHolidays => {
    setStates({ ...states, availabilityJson: { ...states.availabilityJson, holidays: newHolidays } })
  }

  return (
    <>
      <div className="mb-3 form-inline simpleTime">
        Holidays: Closed on:
        <div className="bootstrap-tagsinput">
          {states.availabilityJson.holidays?.length > 0 &&
            states.availabilityJson.holidays?.split(",").map((holiday, i) => {
              return (
                <React.Fragment key={i}>
                  <span className="tag label label-info">
                    {holiday} <span data-role="remove" onClick={e => removeHoliday(i)}></span>
                    <span data-role="drag"></span>
                  </span>{" "}
                </React.Fragment>
              )
            })}
          <input
            ref={inputRef}
            type="text"
            placeholder=""
            value={inputValue}
            onKeyDown={e => {
              if (e.code === "Tab" || e.code === "Enter") {
                if (inputRef.current.value.length > 0) {
                  setHolidays(
                    `${
                      states.availabilityJson.holidays?.length > 0
                        ? states.availabilityJson.holidays + ","
                        : ""
                    }${inputRef.current.value}`
                  )
                  setInputValue("")
                  inputRef.current.focus()
                }
              }
            }}
            onChange={changeHolidays}
          />
        </div>
      </div>
    </>
  )
}

export default Holidays
