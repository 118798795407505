import React from "react"

const Pagination = ({ currentPage, pageCount, onChange, totalItems }) => {
  const itemsPerPage = 25
  const renderPageNumbers = () => {
    const pageNumbers = []
    const range = 2

    let startPage = Math.max(1, currentPage - range)
    let endPage = Math.min(pageCount, currentPage + range)

    if (currentPage - range < 1) {
      startPage = 1
      endPage = Math.min(pageCount, startPage + 2 * range)
    }
    if (currentPage + range > pageCount) {
      endPage = pageCount
      startPage = Math.max(1, endPage - 2 * range)
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={`paginate_button page-item ${i === currentPage ? "active" : ""}`}>
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              onChange(i)
            }}
            aria-controls="ezlinkListTable"
            data-dt-idx={i}
            tabIndex="0"
            className="page-link"
          >
            {i}
          </a>
        </li>
      )
    }
    return pageNumbers
  }

  const startItem = (currentPage - 1) * itemsPerPage + 1
  const endItem = Math.min(currentPage * itemsPerPage, totalItems)

  return (
    <div className="dataTables_wrapper">
      <div className="dataTables_info" id="ezlinkListTable_info" role="status" aria-live="polite">
        {`${startItem} to ${endItem} of ${totalItems}`}
      </div>

      <div className="dataTables_paginate paging_simple_numbers" id="ezlinkListTable_paginate">
        <ul className="pagination">
          <li className={`paginate_button page-item previous ${currentPage === 1 ? "disabled" : ""}`}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                if (currentPage > 1) onChange(1)
              }}
              aria-controls="ezlinkListTable"
              data-dt-idx="previous"
              tabIndex="0"
              className="page-link"
            >
              <i className="bi bi-chevron-double-left"></i>
            </a>
          </li>
          <li className={`paginate_button page-item previous ${currentPage === 1 ? "disabled" : ""}`}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                if (currentPage > 1) onChange(currentPage - 1)
              }}
              aria-controls="ezlinkListTable"
              data-dt-idx="previous"
              tabIndex="0"
              className="page-link"
            >
              <i className="bi bi-chevron-left"></i>
            </a>
          </li>
          {renderPageNumbers()}
          <li className={`paginate_button page-item next ${currentPage === pageCount ? "disabled" : ""}`}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                if (currentPage < pageCount) onChange(currentPage + 1)
              }}
              aria-controls="ezlinkListTable"
              data-dt-idx="next"
              tabIndex="0"
              className="page-link"
            >
              <i className="bi bi-chevron-right"></i>
            </a>
          </li>

          <li className={`paginate_button page-item next ${currentPage === pageCount ? "disabled" : ""}`}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                if (currentPage < pageCount) onChange(pageCount)
              }}
              aria-controls="ezlinkListTable"
              data-dt-idx="next"
              tabIndex="0"
              className="page-link"
            >
              <i className="bi bi-chevron-double-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Pagination
