import React, { useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import { Modal } from "react-bootstrap"

const SchedulerServiceModal = ({ showModal, setShowModal, states, setStates }) => {
  const [name, setName] = useState("")

  const addService = async e => {
    if (!name) {
      console.error("Service name cannot be empty!!")

      return
    }

    try {
      const response = await AxiosInstance.post(`/drive/service/scheduler/${states.fileId}`, { name })
      if (response.status == 201) {
        setStates({ ...states, services: [...states.services, { ...response.data.service, name }] })
        setName("")
        handleClose()

        return
      }
      console.log("Unexpected response status code: ", response.status)
    } catch (error) {
      console.log("Error when creating scheduler: ", error.message)
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header>
        <h1 className="modal-title fs-5" id="addNewServiceModalLabel">
          <i className="bi bi-calendar-plus"></i> Service / Appointment Type
        </h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="row align-items-center">
          <label htmlFor="serviceName" className="col-sm-4 col-form-label">
            Service / Time Block Name{" "}
            <span
              data-bs-toggle="tooltip"
              title="Discovery Call, Dental Cleaning, Snowmobile rental ½ day, Conference Room 2 Hour Block"
            >
              <i className="bi bi-question-circle-fill"></i>
            </span>
          </label>
          <div className="col-sm-8">
            <input
              name="serviceName"
              id="serviceName"
              placeholder="Service Name"
              className="form-control"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" onClick={handleClose}>
          <i className="bi bi-x-lg"></i> Close
        </button>
        <button type="button" className="btn btn-primary" onClick={addService}>
          <i className="bi bi-check2"></i> Save
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SchedulerServiceModal
